import React from 'react';
import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/gardeningetc.css';

loadCSS('gardeningetc-site');

export const GardeningetcSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default GardeningetcSiteWrapper;
